import moment from "moment";
import { useCallback } from "react";

const useDateUtils = () => {
  const getDateDiff = (config: {
    startDate: string;
    endDate: string;
    format?: string;
    diffType?: "day" | "month" | "year" | "minute" | "second" | "hour";
    asString?: boolean;
  }) => {
    const {
      endDate,
      startDate,
      format = "YYYY-MM-DD",
      diffType = "day",
      asString,
    } = config;
    const start = moment(startDate, format);
    const end = moment(endDate, format);
    const diff = end.diff(start, diffType);

    if (asString) {
      return diff > 1 ? `${diff} ${diffType}s` : `${diff} ${diffType}`;
    }
    return diff;
  };

  const getDatePeriods = useCallback(
    (config: {
      dateFrom: string;
      dateTo: string;
      stepUnit?: "days" | "months" | "years";
      step?: number;
    }) => {
      const { dateFrom, dateTo, stepUnit = "days", step = 1 } = config;
      const start = moment(dateFrom);
      const end = moment(dateTo);
      const periods = [];

      while (start <= end) {
        let newDate = moment(start);
        periods.push(newDate);
        start.add(step, stepUnit);
      }
      return periods;
    },
    []
  );

  return { getDateDiff, getDatePeriods };
};

export default useDateUtils;
