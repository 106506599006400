import useSession from "@hooks/use-session";
import { routes } from "@config/routes.config";
import { useMemo } from "react";

const useAppRoutes = () => {
  const { session } = useSession();
  const mappedRoutes = useMemo(() => {
    const selectedRoutes = session?.logged
      ? routes.authenticated
      : routes.unauthenticated;
    return Object.keys(selectedRoutes).reduce((items, currentKey) => {
      items.push({
        path: currentKey,
        Component: selectedRoutes[currentKey],
      });
      return items;
    }, [] as { path: string; Component: any }[]);
  }, [session]);
  return mappedRoutes;
};

export default useAppRoutes;
