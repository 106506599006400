import * as React from "react";
import { CacheStoreType } from "./types";
import { EndpointPathType } from "@providers/api-provider/types";
import { useSession } from "@hooks";

export const CacheCTX = React.createContext<{
  cache?: CacheStoreType;
  setCacheKey: (key: EndpointPathType, data: any) => void;
}>({
  cache: {},
  setCacheKey: () => {},
});
export const CacheCTXProvider = CacheCTX.Provider;
export const CacheCTXConsumer = CacheCTX.Consumer;

interface CacheProviderProps {
  children: React.ReactNode;
}

const CacheProvider: React.FC<CacheProviderProps> = ({ children }) => {
  const { cache, setCacheKey } = useSession();

  return (
    <CacheCTXProvider
      value={{
        cache: cache || {},
        setCacheKey,
      }}
    >
      {Boolean(cache) && children}
    </CacheCTXProvider>
  );
};

CacheProvider.defaultProps = {};

export default CacheProvider;
