const useCurrencyFormat = () => {
  const formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  return (value: any) => {
    return formatter.format(value);
  };
};

export default useCurrencyFormat;
