import { routeAliases } from "@config/routes.config";
import { createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export type RoutingContextType = {
  goTo: (path: RoutePathType, config?: RoutingConfigType) => void;
  redirectTo: (path: RoutePathType, config?: RoutingConfigType) => void;
  goBack: () => void;
  resolvePath: (path: RoutePathType, config?: RoutingConfigType) => string;
};

export const RoutingCTX = createContext<RoutingContextType>({
  goTo: () => {},
  redirectTo: () => {},
  goBack: () => {},
  resolvePath: () => "",
});
export const RoutingCTXProvider = RoutingCTX.Provider;
export const RoutingCTXConsumer = RoutingCTX.Consumer;

interface RoutingContextProps {
  children?: React.ReactNode;
}

type RoutingConfigType = {
  params?: { [key: string]: string };
  state?: { [key: string]: any };
};

const RoutingContext: React.FC<RoutingContextProps> = ({ children }) => {
  const navigate = useNavigate();

  const getUrl = (path: RoutePathType, config?: RoutingConfigType): string => {
    let url = routeAliases[path];
    const { params } = config || {};
    if (params) {
      Object.keys(params).forEach((key) => {
        const value = params[key];
        url = url.replace(`:${key}`, value);
      });
    }
    return url;
  };
  const goTo = (path: RoutePathType, config?: RoutingConfigType) => {
    const url = getUrl(path, config);
    navigate(url);
  };

  const redirectTo = (path: RoutePathType, config?: RoutingConfigType) => {
    const url = getUrl(path, config);
    navigate(url, { replace: true });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <RoutingCTXProvider
      value={{
        goTo,
        goBack,
        redirectTo,
        resolvePath: getUrl,
      }}
    >
      {children}
    </RoutingCTXProvider>
  );
};

export default RoutingContext;
