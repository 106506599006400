import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAdd,
  faAnglesLeft,
  faAnglesRight,
  faBan,
  faBars,
  faBell,
  faBrain,
  faBullseye,
  faCalendar,
  faCalendarAlt,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCogs,
  faComment,
  faCreditCard,
  faCreditCardAlt,
  faDumbbell,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilter,
  faFireBurner,
  faFireAlt,
  faFireFlameCurved,
  faFlag,
  faFlask,
  faFloppyDisk,
  faGauge,
  faGlobe,
  faGraduationCap,
  faHeart,
  faHeartCrack,
  faHeartPulse,
  faHeartCirclePlus,
  faHome,
  faImage,
  faList,
  faLock,
  faLockOpen,
  faMinus,
  faPoo,
  faSearch,
  faStar,
  faStarHalf,
  faStarHalfAlt,
  faTag,
  faTimes,
  faTrash,
  faThumbsUp,
  faThumbsDown,
  faUser,
  faHandFist,
  faFilm,
  faBomb,
  faCloud,
  faFile,
  faPen,
  faPenAlt,
  faClipboard,
  faCar,
  faMugHot,
  faGift,
  faBook,
  faBriefcase,
  faShirt,
  faRocket,
  faLanguage,
  faShower,
  faMotorcycle,
  faTooth,
  faBed,
  faUtensils,
  faStopwatch20,
  faClock,
  faWallet,
  faMoneyBill,
  faMoneyBillTransfer,
  faArrowsRotate,
  faQuestionCircle,
  faCarrot,
  faGasPump,
  faComputer,
  faComputerMouse,
  faDog,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAdd,
  faAnglesLeft,
  faAnglesRight,
  faArrowsRotate,
  faBan,
  faBell,
  faBars,
  faBed,
  faBomb,
  faBook,
  faBriefcase,
  faBrain,
  faBullseye,
  faCarrot,
  faCalendar,
  faCalendarAlt,
  faCar,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClock,
  faCloud,
  faCogs,
  faComment,
  faComputer,
  faComputerMouse,
  faCreditCard,
  faCreditCardAlt,
  faDog,
  faDumbbell,
  faFile,
  faFilter,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFlag,
  faFloppyDisk,
  faFlask,
  faFireBurner,
  faFireAlt,
  faFireFlameCurved,
  faGasPump,
  faGauge,
  faGift,
  faGlobe,
  faGraduationCap,
  faHandFist,
  faFilm,
  faHeart,
  faHeartCrack,
  faHeartPulse,
  faHeartCirclePlus,
  faHome,
  faImage,
  faLanguage,
  faList,
  faLock,
  faLockOpen,
  faMinus,
  faMotorcycle,
  faMoneyBill,
  faMoneyBillTransfer,
  faMugHot,
  faPen,
  faPenAlt,
  faPoo,
  faQuestionCircle,
  faRocket,
  faSearch,
  faShirt,
  faShower,
  faStar,
  faStarHalf,
  faStarHalfAlt,
  faStopwatch20,
  faTag,
  faTimes,
  faTooth,
  faTrash,
  faThumbsUp,
  faThumbsDown,
  faUtensils,
  faUser,
  faWallet
);
