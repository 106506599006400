import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./session.slice";
import cacheReducer from "./cache.slice";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { writeToSession, writeToCahce } from "@utils/session.utils";

const store = configureStore({
  reducer: {
    session: sessionReducer,
    cache: cacheReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(sessionPersistsMiddleware),
});

const handleStoreChange = (store: ToolkitStore) => {
  return () => {
    const { session, cache } = store.getState() || {};
    writeToSession(JSON.stringify(session));
    writeToCahce(JSON.stringify(cache));
  };
};

export const unsuscribe = store.subscribe(handleStoreChange(store));

export default store;
