import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const cacheSlice = createSlice({
  name: "cache",
  initialState: {},
  reducers: {
    hydrateCacheAction: (_, action: PayloadAction<any>) => {
      return action.payload;
    },
    setAllCacheKeysAction: (
      state: any,
      action: PayloadAction<{ [k: string]: any }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearCacheAction: () => {
      return {};
    },
    setCacheKeyAction: (
      state: any,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  hydrateCacheAction,
  setCacheKeyAction,
  clearCacheAction,
  setAllCacheKeysAction,
} = cacheSlice.actions;

export default cacheSlice.reducer;
