import * as React from "react";

interface RouteRendererProps {
  Component: React.ComponentType;
}

const RouteRenderer: React.FC<RouteRendererProps> = ({ Component }) => {
  return (
    <React.Suspense>
      <Component />
    </React.Suspense>
  );
};

RouteRenderer.defaultProps = {};

export default RouteRenderer;
