import * as React from "react";
import moment, { Moment } from "moment";

export const PeriodCTX = React.createContext<{
  dateFrom: Moment;
  dateTo: Moment;
  dateFormat: string;
}>({
  dateFrom: moment(),
  dateTo: moment(),
  dateFormat: "YYYY-MM-DD",
});

export const PeriodCTXProvider = PeriodCTX.Provider;
export const PeriodCTXConsumer = PeriodCTX.Consumer;

interface PeriodProviderProps {
  children: React.ReactNode;
}

const PeriodProvider: React.FC<PeriodProviderProps> = ({ children }) => {
  const currentDate = moment();
  const dateFrom = currentDate.clone().subtract("1", "month").date(26);
  const dateTo = currentDate.clone().date(26);
  return (
    <PeriodCTXProvider
      value={{
        dateFormat: "YYYY-MM-DD",
        dateFrom,
        dateTo,
      }}
    >
      {children}
    </PeriodCTXProvider>
  );
};

PeriodProvider.defaultProps = {};

export default PeriodProvider;
