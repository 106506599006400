import * as React from "react";

export const DashboardPage = React.lazy(() => import("./dashboard-page"));
export const SettingsPage = React.lazy(() => import("./settings-page"));
export const HabitsPage = React.lazy(() => import("./habits/habits-page"));
export const HabitFollowUpsPage = React.lazy(
  () => import("./habits/habit-follow-up-page")
);
export const HabitViewPage = React.lazy(
  () => import("./habits/habit-view-page")
);
export const ActivitiesPage = React.lazy(
  () => import("./activities/activities-page")
);
export const ActivityFollowUpsPage = React.lazy(
  () => import("./activities/activity-follow-up-page")
);
export const ActivityViewPage = React.lazy(
  () => import("./activities/activity-view-page")
);
export const WalletPage = React.lazy(() => import("./wallet/wallet-page"));
export const ExpensesPage = React.lazy(() => import("./wallet/expenses-page"));
