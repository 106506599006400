import * as React from "react";
import styles from "./main-layout.module.scss";
import { useAppConfig, useMediaQuery } from "@hooks";
import classNames from "classnames";

const Toolbar = React.lazy(() => import("@core/tool-bar"));
const NavBar = React.lazy(() => import("@core/nav-bar"));

interface MainLayoutProps {
  children: React.ReactNode;
}

/**
 * Component documentation...
 * @returns React.FC
 */
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { openedNavbar } = useAppConfig();
  const { isIn } = useMediaQuery();
  return (
    <>
      <header className={styles.toolbarWrapper}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Toolbar />
        </React.Suspense>
      </header>
      <div
        className={classNames(styles.navbarWrapper, {
          [styles.navbarMobile]: isIn(["sm", "xs"]),
          [styles.openedNavbarWrapper]: openedNavbar,
        })}
      >
        <React.Suspense fallback={<div>Loading...</div>}>
          <NavBar />
        </React.Suspense>
      </div>
      <div className={styles.mainContent}>{children}</div>
    </>
  );
};

MainLayout.defaultProps = {};

export default MainLayout;
