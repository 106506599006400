import CryptoJS from "crypto-js";
const secret = process.env.REACT_APP_SECRET;
const appName = process.env.REACT_APP_NAME;

export const readFromSession = () => {
  const encryptedSession = localStorage.getItem(`${appName}__session`);

  if (!encryptedSession) return {};
  const decryptedSession = CryptoJS.AES.decrypt(
    encryptedSession,
    secret || ""
  ).toString(CryptoJS.enc.Utf8);

  return JSON.parse(decryptedSession);
};

export const writeToSession = (newValue: string) => {
  const encryptedSession = CryptoJS.AES.encrypt(
    newValue,
    secret || ""
  ).toString();
  localStorage.setItem(`${appName}__session`, encryptedSession);
};

export const writeToCahce = (newValue: string) => {
  localStorage.setItem(`${appName}__cache`, newValue);
};

export const readFromCache = () => {
  const cache = localStorage.getItem(`${appName}__cache`);
  return cache ? JSON.parse(cache) : {};
};
