import * as React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import useAppRoutes from "./useAppRoutes";
import RouteRenderer from "./RouteRenderer";

interface RoutesREndererProps {}

const RoutesREnderer: React.FC<RoutesREndererProps> = () => {
  const routes = useAppRoutes();
  return (
    <Routes>
      {routes?.map((route, key) => (
        <Route
          key={key}
          index
          path={route.path}
          element={<RouteRenderer Component={route.Component} />}
        />
      ))}
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

RoutesREnderer.defaultProps = {};

export default RoutesREnderer;
