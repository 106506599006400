import { useGetLazy } from "@providers/api-provider/hooks";
import { ApiConfigType, EndpointPathType } from "@providers/api-provider/types";
import { CacheCTX } from "@providers/cache-provider/CacheProvider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

const useCacheFetcher = <ResponseType extends any[]>(
  path: EndpointPathType,
  config?: ApiConfigType & {
    noFetch?: boolean;
  }
): [
  ResponseType,
  boolean,
  { refetch: () => Promise<void>; updateCacheRecordById: (data: any) => void }
] => {
  const { noFetch } = config || {};
  const [sendRequest, loading] = useGetLazy<ResponseType>(path, config);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const { cache, setCacheKey } = useContext(CacheCTX);

  const fetchData = useCallback(async () => {
    try {
      const response = await sendRequest();
      setCacheKey(path, response.data);
    } catch (err) {
      console.log("Data: ", err);
    }
  }, [sendRequest, setCacheKey, path]);

  useEffect(() => {
    if (!noFetch && !dataFetched && !cache?.[path]) {
      setDataFetched(true);
      fetchData();
    }
  }, [dataFetched, cache, path, fetchData, noFetch]);

  const refetch = useCallback(async () => {
    const response = await sendRequest();
    setCacheKey(path, response.data);
  }, [sendRequest, path, setCacheKey]);

  const updateCacheRecordById = useCallback(
    (record?: any) => {
      const oldData = cache?.[path];
      if (record && record?.id && Array.isArray(oldData)) {
        setCacheKey(
          path,
          oldData.map((item) => {
            if (item.id === record.id) {
              return { ...item, ...record };
            }
            return item;
          })
        );
      }
    },
    [cache, path, setCacheKey]
  );

  const data: ResponseType = useMemo(() => {
    if (cache?.[path]) {
      return cache?.[path];
    }
  }, [cache, path]);

  return [data, loading, { refetch, updateCacheRecordById }];
};

export default useCacheFetcher;
