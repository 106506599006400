interface EmptyLayoutProps {
  children: React.ReactNode;
}

/**
 * Component documentation...
 * @returns React.FC
 */
const EmptyLayout: React.FC<EmptyLayoutProps> = ({ children }) => {
  return <>{children}</>;
};

EmptyLayout.defaultProps = {};

export default EmptyLayout;
