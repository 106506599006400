import { useGet } from "@providers/api-provider/hooks";
import { ApiConfigType, EndpointPathType } from "@providers/api-provider/types";
import { useMemo } from "react";

const useDataGetter = <ResponseType extends []>(
  path: EndpointPathType,
  config?: ApiConfigType & {
    mapper?: { label: string; value: string };
  }
) => {
  const { mapper, ...otherConfig } = config || {};
  const [response, loading] = useGet<ResponseType>(path, otherConfig);
  const data = useMemo(() => {
    if (mapper && response && Array.isArray(response)) {
      return response.map((item: any) => ({
        label: item[mapper.label],
        value: item[mapper.value],
      }));
    }
    return response;
  }, [response, mapper]);
  return [data, loading];
};

export default useDataGetter;
