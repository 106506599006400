import {
  DashboardPage,
  LandingPage,
  LoginPage,
  RegisterPage,
  SettingsPage,
  TestingHallPage,
  ActivitiesPage,
  ActivityFollowUpsPage,
  HabitsPage,
  HabitFollowUpsPage,
  HabitViewPage,
  ActivityViewPage,
  WalletPage,
  ExpensesPage,
} from "@pages";
export const routeAliases = {
  home: "/",
  login: "/login",
  register: "/register",
  settings: "/settings",
  testingHall: "/testing-hall",
  activities: "/activities/manage",
  activityView: "/activities/manage/:activityId/view",
  activityFollowUps: "/activity/follow-ups",
  habits: "/habits/manage",
  habitView: "/habits/manage/:habitId/view",
  habitFollowUps: "/habit/follow-ups",
  walletManage: "/wallet/manage",
  walletExpenses: "/wallet/expenses",
};

type RouteStackType = {
  [k: string]: React.ComponentType;
};

export const routes: {
  authenticated: RouteStackType;
  unauthenticated: RouteStackType;
} = {
  authenticated: {
    [routeAliases.home]: DashboardPage,
    [routeAliases.settings]: SettingsPage,
    [routeAliases.activities]: ActivitiesPage,
    [routeAliases.activityFollowUps]: ActivityFollowUpsPage,
    [routeAliases.habits]: HabitsPage,
    [routeAliases.habitFollowUps]: HabitFollowUpsPage,
    [routeAliases.testingHall]: TestingHallPage,
    [routeAliases.habitView]: HabitViewPage,
    [routeAliases.activityView]: ActivityViewPage,
    [routeAliases.walletManage]: WalletPage,
    [routeAliases.walletExpenses]: ExpensesPage,
  },
  unauthenticated: {
    [routeAliases.home]: LandingPage,
    [routeAliases.login]: LoginPage,
    [routeAliases.register]: RegisterPage,
  },
};
