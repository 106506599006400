import { useState, useEffect, useCallback } from "react";
import { createContext } from "react";
import { readFromCache, readFromSession } from "@utils/session.utils";
import {
  hydrateSessionAction,
  setKeyAction,
  clearAction,
  setAllKeysAction,
} from "@session/redux/session.slice";
import {
  hydrateCacheAction,
  setAllCacheKeysAction,
  clearCacheAction,
  setCacheKeyAction,
} from "@session/redux/cache.slice";
import { useDispatch, useSelector } from "react-redux";

type SessionProviderType = {
  session: SessionType;
  cache: any;
  setKey: (k: keyof SessionType, v: any) => void;
  clear: () => void;
  setAllKeys: (keys: SessionType) => void;
  setCacheKey: (k: string, v: any) => void;
  setAllCacheKeys: (keys: { [k: string]: any }) => void;
};

export const SessionCtx = createContext<SessionProviderType>({
  cache: {},
  session: {},
  setKey: () => null,
  setAllKeys: (d: any) => null,
  clear: () => null,
  setCacheKey: () => null,
  setAllCacheKeys: () => null,
});
export const SessionCTXProvider = SessionCtx.Provider;
export const SessionCTXConsumer = SessionCtx.Consumer;

interface SessionProviderProps {
  children: React.ReactNode;
}

const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  const [hydrated, setHydrated] = useState(false);
  const dispatch = useDispatch();
  const session = useSelector((state: any) => state.session);
  const cache = useSelector((state: any) => state.cache);
  const read = useCallback(() => {
    const session = readFromSession();
    return session;
  }, []);
  const readCache = useCallback(() => {
    const fetchedCache = readFromCache();
    return fetchedCache;
  }, []);

  const clear = () => {
    dispatch(clearAction());
  };

  const setAllKeys = (keys: { [k: string]: any }) => {
    dispatch(setAllKeysAction(keys));
  };

  const setKey = useCallback(
    (key: string, value: any) => {
      dispatch(setKeyAction({ key, value }));
    },
    [dispatch]
  );

  const setCacheKey = useCallback(
    (key: string, value: any) => {
      dispatch(setCacheKeyAction({ key, value }));
    },
    [dispatch]
  );

  const setAllCacheKeys = useCallback(
    (keys: { [k: string]: any }) => {
      dispatch(setAllCacheKeysAction(keys));
    },
    [dispatch]
  );

  const clearCache = useCallback(() => {
    dispatch(clearCacheAction());
  }, [dispatch]);

  const hydrate = useCallback(() => {
    const session = read();
    const cache = readCache();
    dispatch(hydrateSessionAction(session));
    dispatch(hydrateCacheAction(cache));
  }, [read, readCache, dispatch]);

  const hydateSession = useCallback(() => {
    if (!hydrated) {
      hydrate();
      setHydrated(true);
    }
  }, [hydrated, hydrate]);

  useEffect(() => {
    hydateSession();
  }, [hydateSession]);

  return (
    <SessionCTXProvider
      value={{
        cache,
        clear,
        setAllKeys,
        session,
        setKey,
        setCacheKey,
        setAllCacheKeys,
      }}
    >
      {hydrated ? (
        children
      ) : (
        <>
          <p>Loading session...</p>
        </>
      )}
    </SessionCTXProvider>
  );
};

export default SessionProvider;
