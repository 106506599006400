import { CacheProvider, SessionProvider } from "@providers";
import { Provider as ReduxProvider } from "react-redux";
import store from "@session/redux/store";
import AppRouter from "@navigation/AppRouter";
import ApiContext from "@providers/api-provider/ApiContext";
import AppContext from "@providers/app-provider/AppProvider";
import MediaQueryProvider from "@providers/media-query-provider";
import PeriodProvider from "@providers/period-provider";

interface AppWrapperProps {
  children?: React.ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  return (
    <AppContext>
      <ReduxProvider store={store}>
        <SessionProvider>
          <MediaQueryProvider>
            <ApiContext>
              <CacheProvider>
                <PeriodProvider>
                  <AppRouter />
                </PeriodProvider>
              </CacheProvider>
            </ApiContext>
          </MediaQueryProvider>
        </SessionProvider>
      </ReduxProvider>
    </AppContext>
  );
};

AppWrapper.defaultProps = {};

export default AppWrapper;
