import moment from "moment";
import { useMemo } from "react";

const useDateDiff = (config: {
  startDate: string;
  endDate: string;
  format?: string;
  diffType?: "days" | "months" | "years" | "minutes" | "seconds" | "hours";
}) => {
  const {
    endDate,
    startDate,
    format = "YYYY-MM-DD",
    diffType = "days",
  } = config;
  const diffDays = useMemo(() => {
    const start = moment(startDate, format);
    const end = moment(endDate, format);
    return end.diff(start, diffType);
  }, [startDate, endDate, format, diffType]);
  return diffDays;
};

export default useDateDiff;
