import { BrowserRouter as Router } from "react-router-dom";
import RoutesRenderer from "./RoutesRenderer";
import RoutingContext from "./RoutingContext";
import { useSession } from "@hooks";
import { EmptyLayout, MainLayout } from "@layouts";

const AppRouter = () => {
  const { session } = useSession();
  const Layout = session.logged ? MainLayout : EmptyLayout;

  return (
    <Router>
      <RoutingContext>
        <Layout>
          <RoutesRenderer />
        </Layout>
      </RoutingContext>
    </Router>
  );
};

export default AppRouter;
